export default function () {
  const video = document.querySelector('video');
  const poster = document.querySelector('.poster');
  const controls = document.querySelector('.controls');
  const control = document.querySelector('.control');
  const start = document.querySelector('.start');
  const play = document.querySelector('.play');
  const pause = document.querySelector('.pause');

  if (start) {
    start.addEventListener('click', function() {
      video.play();
      poster.classList.add('drop');
      controls.classList.toggle('hidden');
    });
  }

  if (control) {
    control.addEventListener('click', function() {
      if (video.paused == true) {
        video.play();
        play.classList.toggle('hidden');
        pause.classList.toggle('hidden');
      } else {
        video.pause();
        play.classList.toggle('hidden');
        pause.classList.toggle('hidden');
      }
    });
  }
}