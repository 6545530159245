import SmoothScroll from 'smooth-scroll';

export default () => {
  const connect = new SmoothScroll('a[href*="#"]', {
    topOnEmptyHash: false,
    speed: 1000,
    offset: 100,
    easing: 'easeInOutCubic',
    updateURL: false
  });

  const top = new SmoothScroll('a[href="#top"]', {
    topOnEmptyHash: false,
    speed: 350,
    easing: 'easeInOutCubic',
    updateURL: false
  });
}