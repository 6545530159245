import enterView from '../plugins/enter-view.js';
import { CountUp } from '../plugins/countUp.js';

export default function () {
  const record = document.querySelector('.track-record');

  if(record) {
    const stat01 = document.querySelector('.stat-01');
    const stat01txt = stat01.innerText;
    const stat01num = stat01txt.replace(/[^\d.-]/g, '');

    const countStat01 = new CountUp(stat01, stat01num, {
      startVal: 100,
      duration: 4,
      prefix: '$',
      suffix: 'M+'
    });

    const stat02 = document.querySelector('.stat-02');
    const stat02txt = stat02.innerText;
    const stat02num = stat02txt.replace(/[^\d.-]/g, '');

    const countStat02 = new CountUp(stat02, stat02num, {
      startVal: 1.0,
      decimalPlaces: 1,
      duration: 4,
      suffix: 'M+'
    });

    const stat03 = document.querySelector('.stat-03');
    const stat03txt = stat03.innerText;
    const stat03num = stat03txt.replace(/[^\d.-]/g, '');

    const countStat03 = new CountUp(stat03, stat03num, {
      startVal: 100,
      duration: 4,
      prefix: '$',
      suffix: 'M+'
    });

    const stat04 = document.querySelector('.stat-04');
    const stat04txt = stat04.innerText;
    const stat04num = stat04txt.replace(/[^\d.-]/g, '');

    const countStat04 = new CountUp(stat04, stat04num, {
      startVal: 100,
      duration: 4
    });

    enterView({
    	selector: '.track-record',
    	enter: function(el) {
        setTimeout(function(){
          countStat01.start();
          countStat02.start();
          countStat03.start();
          countStat04.start();
        }, 750);
    	},
      exit: function(el) {
        countStat01.reset();
        countStat02.reset();
        countStat03.reset();
        countStat04.reset();
      }
    });
  }
}