import '../styles/main.scss';

import accordion from './parts/accordion.js';
import headspace from './plugins/headspace.js';
import jackpot from './parts/jackpot.js';
import mobileNav from './parts/mobile-nav.js';
import smoothScroll from './plugins/smooth-scroll.js';
import teamModals from './parts/team-modals.js';
import videoControls from './parts/video-controls.js';
import youtubePlayer from './parts/youtube-player.js';

accordion();
headspace();
jackpot();
mobileNav();
smoothScroll();
teamModals();
videoControls();
youtubePlayer();