export default function () {
  const accordion = document.getElementsByClassName('accordion');

  for (var i = 0; i < accordion.length; i++) {
    accordion[i].addEventListener('click', function(e) {
      const indicator = this.querySelector('.indicator');
      const height = this.nextElementSibling.scrollHeight;
      const learn = this.querySelector('.learn-more');
      if(!e.target.classList.contains('learn-more')){
        this.classList.toggle('active');
        indicator.classList.toggle('active');
        this.nextElementSibling.classList.toggle('active');
        this.nextElementSibling.style.setProperty('--max-height', height + 'px');
      }
    });
  }

  window.addEventListener('resize', function() {
    for (var i = 0; i < accordion.length; i++) {
      const height = accordion[i].nextElementSibling.scrollHeight;
      accordion[i].nextElementSibling.style.setProperty('--max-height', height + 'px');
    }
  });

  document.querySelectorAll('.panel .content').forEach(panel => {
    if (panel.children.length > 1) {
      panel.classList.add('columns');
    }
  });
}