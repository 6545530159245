import Headspace from 'headspace';

export default function() {
  Headspace(document.querySelector('.header'), {
    startOffset: 125,
    tolerance: 10,
    showAtBottom: false,
    classNames: {
      base: 'headspace',
      fixed: 'headspace--fixed',
      hidden: 'headspace--hidden'
    }
  });
}
