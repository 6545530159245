export default function () {
  const body = document.querySelector('body');
  const child = document.querySelector('.member-details');
  const close = document.querySelectorAll('.close-modal');
  const details = document.querySelectorAll('.member-details');
  const modals = document.querySelector('.team-modals');
  const toggles = document.querySelectorAll('[data-toggle="modal"]');

  const scrollLock = function() {
    if (body.style.overflow != 'hidden') {
      body.style.overflow = 'hidden';
    } else {
      body.removeAttribute('style');
    }
  }

  if(modals){
    for(var i = 0; i < toggles.length; i++){
      toggles[i].onclick = function() {
        const target = this.getAttribute('data-target');
        const modal = document.getElementById(target);
        modals.classList.toggle('active');
        modal.classList.remove('hidden');
        scrollLock();
      }
    }

    for(var i = 0; i < close.length; i++){
      close[i].onclick = function() {
        modals.classList.toggle('active');
        details.forEach(member => {
          member.classList.add('hidden');
          scrollLock();
        });
      }
    }

    modals.addEventListener('click', function(e){
      if (!child.contains(e.target)){
        modals.classList.remove('active');
        details.forEach(member => {
          member.classList.add('hidden');
        });
        scrollLock();
      }
    });
  }
}