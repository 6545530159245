export default function () {
  const body = document.querySelector('body');
  const header = document.querySelector('.header');
  const hamburger = document.querySelector('.hamburger');
  const mobileNav = document.querySelector('.mobile-nav');

  const toggleScrollLock = function() {
    if (body.style.overflow != 'hidden') {
      body.style.overflow = 'hidden';
    } else {
      body.removeAttribute('style');
    }
  }

  const toggleMenu = function() {
    header.classList.toggle('mobile-active');
    hamburger.classList.toggle('active');
    mobileNav.classList.toggle('active');
    //mobileNav.toggleAttribute('inert');
    toggleScrollLock();
  }

  const closeMenu = function() {
    header.classList.remove('mobile-active');
    hamburger.classList.remove('active');
    mobileNav.classList.remove('active');
    body.removeAttribute('style');
  }

  hamburger.onclick = () => toggleMenu();

  window.addEventListener('resize', function() {
    closeMenu();
  });
}
